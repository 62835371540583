import React, { useEffect } from "react"

import { useToasts } from "react-toast-notifications"
import { Modal, Input, Row, Col, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientInfo,
  postOutPatientAddressAction,
  getOutPatientAddressAction,
  getPatientMenuConfigAction
} from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Loading from "../../layouts/Loading/Loading"

const schema = yup
  .object({
    street: yup
      .string()
      //.matches(/^[A-Za-z1-9]*$/, "Street should only contain alphabets or numbers")
      .required("Invalid Street"),
    city: yup
      .string()
      //.matches(/^[A-Za-z1-9]*$/, "City should only contain alphabets or numbers")
      .required("Invalid City"),
    state: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "State should only contain alphabets")
      .required("Invalid State"),
    zipcode: yup.string().min(5).max(5).required("Invalid Zipcode")
  })
  .required()

const UpdatePatientAddress = props => {
  const { updateAddressShow, handleUpdateAddressClose } = props

  const dispatch = useDispatch()

  const { patientInfoDetail } = useSelector(state => state.patientInfo)
  const { outpatientAddress, loading } = useSelector(state => state.outpatientAddress)
  const { loading: postLoading, error: postError} = useSelector(state => state.postOutpatientAddress)

  const { addToast } = useToasts()

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      apartmentNumber: outpatientAddress?.flatNo || null,
      street: outpatientAddress?.street || "",
      city: outpatientAddress?.city || "",
      state: outpatientAddress?.state || "",
      zipcode: outpatientAddress?.zipcode || ""
    }
  })

  useEffect(() => {
    if (updateAddressShow) {
      dispatch(getOutPatientAddressAction(patientInfoDetail?.id))
    }
  }, [updateAddressShow])

  useEffect(() => {
    if (outpatientAddress) {      
      setValue("apartmentNumber", outpatientAddress?.flatNo?.length ? outpatientAddress?.flatNo : null)
      setValue("street", outpatientAddress?.street)
      setValue("city", outpatientAddress?.city)
      setValue("state", outpatientAddress?.state)
      setValue("zipcode", outpatientAddress?.zipcode)
    }
  }, [loading])

  const handleClose = () => {
    reset()
    handleUpdateAddressClose()
  }

  const onSubmit = async data => {
    const result = dispatch(
      postOutPatientAddressAction(patientInfoDetail?.id, {
        ...data,
        flatNo: data?.apartmentNumber?.length ? data?.apartmentNumber : null
      })
    )
    result.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        handleUpdateAddressClose()
        addToast("Patient Updated Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        dispatch(getPatientMenuConfigAction(patientInfoDetail?.id))
        const patientId = { patientId: patientInfoDetail?.id }
        dispatch(getPatientInfo(patientId))
        dispatch(getOutPatientAddressAction(patientInfoDetail?.id))
      }
    })
  }

  return (
    <Modal
      width={600}
      footer={null}
      onCancel={handleClose}
      open={updateAddressShow}
      title={<h4 className="text-xl">Update Address</h4>}>
      {loading ? (
        <Loading />
      ) : (
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-street">
                Street <span className="text-danger">*</span>
              </label>
              <Controller
                name="street"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.street ? "error" : undefined}
                    size="large"
                    id="input-street"
                    {...field}
                  />
                )}
              />
              {errors.street ? (
                <small className="text-danger">{errors.street.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-1" htmlFor="input-apartmentNumber">
                Apartment Number (optional)
              </label>
              <Controller
                name="apartmentNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-apartmentNumber"
                    status={errors.apartmentNumber ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.apartmentNumber ? (
                <small className="text-danger">{errors.apartmentNumber.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-city">
                City <span className="text-danger">*</span>
              </label>
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.city ? "error" : undefined}
                    size="large"
                    id="input-city"
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.city ? <small className="text-danger">{errors.city.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-state">
                State <span className="text-danger">*</span>
              </label>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.state ? "error" : undefined}
                    size="large"
                    id="input-state"
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.state ? <small className="text-danger">{errors.state.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-zipcode">
                Zipcode <span className="text-danger">*</span>
              </label>
              <Controller
                name="zipcode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="number"
                    id="input-zipcode"
                    status={errors.zipcode ? "error" : undefined}
                    {...field}
                    onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.zipcode ? (
                <small className="text-danger">{errors.zipcode.message}</small>
              ) : null}
            </Col>
            {postError ? (
              <Col className="my-2" xs={24}>
                <p className="text-danger font-semibold">{postError}</p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3">
                <Button size="large" type="primary" htmlType="submit" loading={postLoading}>
                  Submit
                </Button>
                <Button size="large" danger onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  )
}

export default UpdatePatientAddress
