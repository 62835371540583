import { useState, useEffect, useRef } from "react"

import moment from "moment"
import { Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { Modal, Checkbox, Select, Button, Radio } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getrpmDeviceActions } from "../../../redux/actions/rpmAction"
import { getProviderList } from "../../../redux/actions/providerAction"
import {
  getPatientInfo,
  postMonitoringData,
  deleteMonitoringData,
  getPatientMonitoringData,
  getPatientDiagnosticsData,
  postPatientDiagnosticsData,
  deletePatientDiagnosticsData,
  getPatientMenuConfigAction
} from "../../../redux/actions/patientAction"

import Autosuggest from "react-autosuggest"

import { FiMinusSquare } from "react-icons/fi"

import Loading from "../../layouts/Loading/Loading"
import { useToasts } from "react-toast-notifications"
import { PATIENT_DIAGNOSTIC_RESET } from "../../../redux/types/PatientConstant"
import { getPractitionerList } from "../../../redux/actions/practitionerAction"
import { useParams } from "react-router-dom"

const CheckboxGroup = Checkbox.Group

const getProviderOption = (arr, identifier) => {
  if (arr) {
    return arr.map(provider => ({
      label: <span className="capitalize">{`${provider?.firstName} ${provider?.middleName ?? ''} ${provider?.lastName}`}</span>,
      value: `${identifier}:${provider.id}`
    }))
  } else {
    return []
  }
}

const getSelectedProvider = (providerList, selectedDevices, value) => {
  if (selectedDevices) {
    const deviceValue = selectedDevices.find(
      i => i?.provider_practitioner_id?.split(":")?.shift() === value
    )?.provider_practitioner_id
    const provider_practitioner_id = deviceValue?.split(":")?.[1]
    if (providerList?.find(({ id }) => provider_practitioner_id === id))
      return deviceValue
    else return ""
  } else {
    return ""
  }
}

const getPractitionerOption = (arr, identifier) => {
  if (arr) {
    return arr.map(practitioner => ({
      label: <span className="capitalize">{`${practitioner?.firstName} ${practitioner?.middleName ?? ''} ${practitioner?.lastName}`}</span>,
      value: `${identifier}:${practitioner.id}`
    }))
  } else {
    return []
  }
}

const getSelectedPractitioner = (practitionerList, selectedDevices, value) => {
  if (selectedDevices) {
    const deviceValue = selectedDevices.find(
      i => i?.provider_practitioner_id?.split(":")?.shift() === value
    )?.provider_practitioner_id
    const provider_practitioner_id = deviceValue?.split(":")?.[1]
    if (practitionerList?.find(({ id }) => provider_practitioner_id === id))
      return deviceValue
    else return ""
  } else {
    return ""
  }
}

const scrollToElement = (elementRefs, key) => {
  const element = elementRefs?.current[key];
  if (element) {
    element?.input?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
};

const getSuggestions = async value => {
  const inputValue = value.trim().toLowerCase()
  let response = await fetch(
    `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${inputValue}`
  )
  let data = await response.json()
  return data[3]
}

const CCM_DIAGNOSTIC_ERR_MSG = "Minimum 2 Diagnostic codes must be selected as per current Monitor selection"
const PCM_DIAGNOSTIC_ERR_MSG = "Minimum 1 Diagnostic codes must be selected as per current Monitor selection"
const SELECT_MONITOR_TYPE_MSG = "Please Select Monitor Types"
const SELECT_MONITOR_DEVICE_MSG = "Please Select Monitor Device For The Selected Monitor Types"
const MONITORING_DIA_UPDT_MSG = "Monitoring and Diagnostics updated!"

const UpdateMonitoring = props => {
  const { patientId, updateMonitoringShow, patientMonitoringData, handleUpdateMonitoringClose } =
    props

  const [value, setValue] = useState("")
  const [selectedRPM, setSelectedRPM] = useState([])
  const [customError, setCustomError] = useState([])
  const [diagnostics, setDiagnostics] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [diagnosticError, setDiagnosticError] = useState(null)
  const [diagnosticDate, setDiagnosticDate] = useState(new Date())
  const [providerTypeRadio, setProviderTypeRadio] = useState({})
  const elementRefs = useRef({});

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {orgId} = useParams()

  const { providerList } = useSelector(state => state.providerList)
  const { practitionerList } = useSelector(state => state.practitionerList)

  const { loading, error: patientMonitoringError } = useSelector(state => state.patientMonitoringData)
  // const { loading: rpmDeviceLoading, rpmDevices } = useSelector(state => state.rpmDevices)
  const { loading: DiagnosticsLoading, patientDiagnosticData, error: patientDiagnosticsError } = useSelector(
    state => state.patientDiagnosticData
  )
  const { error: diagnosticPostError } = useSelector(state => state.postPatientDiagnosticReducer)
  const rpmDevices = JSON.parse(localStorage.getItem("monitorTypes"))

  const handleClose = () => {
    setDiagnosticError(null)
    // dispatch({type: PATIENT_DIAGNOSTIC_RESET})
    handleUpdateMonitoringClose()
  }

  useEffect(() => {
    if (updateMonitoringShow) {
      dispatch(getProviderList(orgId))
      dispatch(getPractitionerList(orgId))
      if (!rpmDevices?.length) dispatch(getrpmDeviceActions())
      dispatch(getPatientDiagnosticsData({ patientId }))
    }
  }, [updateMonitoringShow])

  useEffect(() => {
    if (!updateMonitoringShow) return
    if (patientMonitoringData && Object.keys(patientMonitoringData).length) {
      const arr = []
      const providerTypeRadioInitial = {}
      setSelectedTypes([...Object.keys(patientMonitoringData)])
      // Object.keys(patientMonitoringData).map(key => {
      //   patientMonitoringData[key].map(data => {
      //     arr.push({
      //       programType: key,
      //       programId: data.programs.programId,
      //       programName: data.programs.programName,
      //       providerId: `${key} ${data.programs.programName}:${data.providerId}`
      //     })
      //   })
      // })
      Object.keys(patientMonitoringData).map(key => {
        patientMonitoringData[key].map(data => {
          data?.programs?.map((programs) => {
            arr.push({
              programType: data.monitoringType,
              programId: programs.programId,
              programName: programs.programName,
              provider_practitioner_id: `${data.monitoringType} ${programs.programName}:${data.providerId ?? data.practitionerId}`,
              providerType: data?.providerId ? "provider" : "practitioner"
            })
            providerTypeRadioInitial[`${data.monitoringType} ${programs.programName}`] =
              (data?.providerId ? "provider" : "practitioner") +
              `~${data?.monitoringType} ${programs?.programName}~${programs?.programId}`
          })
        })
      })
      setSelectedRPM([...arr])
      setProviderTypeRadio(providerTypeRadioInitial)
    }
  }, [patientMonitoringData, updateMonitoringShow])

  useEffect(() => {
    if (patientDiagnosticData && patientDiagnosticData.length) {
      setDiagnostics([...patientDiagnosticData])
    }
  }, [patientDiagnosticData?.length])

  useEffect(() => {
    if (patientMonitoringData) {
      handleDiagnosticValidation()
    }
  }, [selectedRPM, diagnostics])

  const handleDiagnosticValidation = callback => {
    if (selectedRPM.length) {
      const selectedProgramTypes = selectedRPM.map(i => i.programType)
      const noDeviceValidation = selectedProgramTypes.length === selectedTypes.length
      const defaultPrograms = Object?.keys(patientMonitoringData)
        .map(key => patientMonitoringData[key]?.map(i => i.programs.map((i) => i.programId)))
        .flat(Infinity)
      const arr = selectedRPM.filter(i => (!defaultPrograms.includes(i.programId) ? i : null))

      // if (noDeviceValidation === false) {
      //   setDiagnosticError("Please Select A Device")
      // } else 
      if (arr.some(i => i.provider_practitioner_id === null)) {
        setDiagnosticError(null)
        const nullProviderOrPractitionerId = arr
          .filter(i => i.provider_practitioner_id === null && !customError.includes(i.programId))
          .map(i => i.programId)
        setCustomError(prevErr => [...prevErr, ...nullProviderOrPractitionerId])
        //setCustomError(arr.map(i => (i.providerId === null ? i.programId : null)))
      }
      if (diagnostics.length) {
        if (
          ((selectedProgramTypes.includes("ccm") && selectedProgramTypes.includes("rpm"))
            || (selectedProgramTypes.includes("ccm") && selectedProgramTypes.includes("rtm - pain management"))
          ) &&
          diagnostics.length < 2
        ) {
          setDiagnosticError(CCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          selectedProgramTypes.includes("ccm") && diagnostics.length < 2
        ) {
          setDiagnosticError(CCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          selectedProgramTypes.includes("pcm") && diagnostics.length < 1
        ) {
          setDiagnosticError(PCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          !selectedProgramTypes.includes("rpm") &&
          diagnostics.length === 0
        ) {
          setDiagnosticError(PCM_DIAGNOSTIC_ERR_MSG)
        } else {
          if (callback) {
            callback()
          } else {
            // setCustomError([])
            setDiagnosticError(null)
          }
        }
      } else {
        if (
          ((selectedProgramTypes.includes("ccm") && selectedProgramTypes.includes("rpm"))
            || (selectedProgramTypes.includes("ccm") && selectedProgramTypes.includes("rtm - pain management"))
          ) &&
          diagnostics.length < 2
        ) {
          setDiagnosticError(CCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          selectedProgramTypes.includes("ccm") && diagnostics.length < 2
        ) {
          setDiagnosticError(CCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          selectedProgramTypes.includes("pcm") && diagnostics.length < 1
        ) {
          setDiagnosticError(PCM_DIAGNOSTIC_ERR_MSG)
        } else if (
          !selectedProgramTypes.includes("rpm") && diagnostics.length === 0
        ) {
          setDiagnosticError(PCM_DIAGNOSTIC_ERR_MSG)
        } else {
          setDiagnosticError(PCM_DIAGNOSTIC_ERR_MSG)
        }
      }
    } else {
      if (selectedTypes.length === 0) {
        setDiagnosticError(SELECT_MONITOR_TYPE_MSG)
      } else {
        selectedTypes.includes("rpm") || selectedRPM.includes() ?
          setDiagnosticError(SELECT_MONITOR_DEVICE_MSG)
          : setDiagnosticError(SELECT_MONITOR_TYPE_MSG)
      }
    }
  }

  const handleSelectedTypes = e => {
    const type = e.target.name
    let deletedValue
    if (!e.target.checked) {
      deletedValue = selectedTypes.filter(i => i === type)
    }
    let splicedValues = [...selectedTypes]
    if (deletedValue?.length) {
      const deletedValueIndex = selectedTypes?.indexOf(deletedValue[0])
      splicedValues?.splice(deletedValueIndex, 1)
      setSelectedTypes(splicedValues)
    } else {
      if (type === "pcm" && selectedTypes.includes("ccm")) {
        const ccmIndex = selectedTypes?.indexOf("ccm")
        splicedValues?.splice(ccmIndex, 1)
      } else if (type === "ccm" && selectedTypes.includes("pcm")) {
        const pcmIndex = selectedTypes?.indexOf("pcm")
        splicedValues?.splice(pcmIndex, 1)
      }
      setSelectedTypes([...splicedValues, type])
    }
    if (deletedValue && deletedValue.length) {
      const splicedRPM = selectedRPM.filter((i) => i.programType !== deletedValue[0])
      setSelectedRPM([...splicedRPM])
    }
  }

  const handleSelectedRPMDevices = (e, value, programType) => {
    if (e.target.checked) {
      const program = rpmDevices
        .map(i => i.programs.find(j => j.id === value))
        .filter(r => r !== undefined)[0]
      setSelectedRPM([
        ...selectedRPM,
        {
          programType,
          provider_practitioner_id: null,
          programId: program.id,
          programName: program.name
        }
      ])
      handleDiagnosticValidation()
    } else {
      setCustomError(prev => prev.filter(programId => programId !== value))
      const removed = selectedRPM
      const index = removed.findIndex(r => r.programId === value)
      if (index !== undefined || index !== null) {
        removed.splice(index, 1)
        setSelectedRPM([...removed])
        // patientMonitoringData?.rpm?.map(data => { 
        //   if(data?.programs[0]?.programId === value) 
        //   dispatch(deleteMonitoringData(patientId, value)).then(res => {
        //     if (res?.message?.toLowerCase() === "success") {
        //       dispatch(getPatientMonitoringData({ patientId }))
        //     }
        //   })})
        handleDiagnosticValidation()
      }
    }
    handleDiagnosticValidation()
  }

  const handleProviderChange = (val, deviceName) => {
    const defaultArr = selectedRPM

    const updated = defaultArr.map(obj => {
      if (
        deviceName?.toLowerCase() ===
        `${obj?.programType?.toLowerCase()} ${obj?.programName?.toLowerCase()}`
      ) {
        customError.length && customError.includes(obj.programId)
          ? setCustomError([...customError.filter(e => e !== obj.programId)])
          : null
        obj = { ...obj, provider_practitioner_id: val, providerType: "provider" }
        return obj
      } else {
        return obj
      }
    })
    setSelectedRPM([...updated])
  }

  const handlePractitionerChange = (val, deviceName) => {
    const defaultArr = selectedRPM

    const updated = defaultArr.map(obj => {
      if (
        deviceName?.toLowerCase() ===
        `${obj?.programType?.toLowerCase()} ${obj?.programName?.toLowerCase()}`
      ) {
        customError.length && customError.includes(obj.programId)
          ? setCustomError([...customError.filter(e => e !== obj.programId)])
          : null
        obj = { ...obj, provider_practitioner_id: val, providerType: "practitioner" }
        return obj
      } else {
        return obj
      }
    })
    setSelectedRPM([...updated])
  }

  const handleProviderTypeRadio = (e) => {
    const value = e.target.value
    const device = value?.split("~")?.[1]
    setProviderTypeRadio(prev => ({ ...prev, [device]: value }))

    const programId = value?.split("~")?.[2]

    if (!customError.includes(programId)) setCustomError(prev => [...prev, programId])

    setSelectedRPM(prev => prev.map((data) => data?.programId === programId ? { ...data, provider_practitioner_id: null } : data))
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    const getData = await getSuggestions(value)
    let dataList = []
    const arrayToMap = getData.filter(
      dc =>
        !diagnostics?.some(({ icdCode: id2 }) => {
          return dc[0].toLowerCase() === id2.toLowerCase()
        })
    )

    arrayToMap.map(item =>
      dataList.push({
        description: item?.[1],
        icdCode: item?.[0]
      })
    )
    setSuggestions(dataList)
    handleDiagnosticValidation()
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestionValue = suggestion => {
    setDiagnostics([
      ...diagnostics,
      {
        description: suggestion.description,
        icdCode: suggestion?.icdCode,
        diagnosedDate: diagnosticDate
      }
    ])
    return (suggestion.description = "")
  }

  const handleDiagnosticsDate = (date, index) => {
    const list = [...diagnostics]
    list[index]["diagnosedDate"] = date
    setDiagnostics(list)
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const renderSuggestion = suggestion => <div>{suggestion?.description}</div>

  const handleDiagnosticDelete = (id, icdCode) => {
    if (icdCode) {
      setDiagnostics((selectedDiagnostics) => selectedDiagnostics.filter((diagnotic) => diagnotic?.icdCode?.toLowerCase() !== icdCode?.toLowerCase()))
    }
    if (id) {
      setDiagnostics((selectedDiagnostics) => selectedDiagnostics.filter((diagnotic) => diagnotic?.id?.toLowerCase() !== id?.toLowerCase()))
    }
    //if(id){dispatch(deletePatientDiagnosticsData(patientId, id)).then(res => {
    // setDiagnostics((selectedDiagnostics) => selectedDiagnostics.filter((diagnotic) => diagnotic?.id?.toLowerCase() !== id?.toLowerCase()) )
    // dispatch(getPatientDiagnosticsData({ patientId })).then(() => {
    //   handleDiagnosticValidation()
    // })
    //})}
    handleDiagnosticValidation()
  }

  const handleDiagnosticsSubmit = callback => {
    const filtered = diagnostics.filter(
      ({ icdCode: id1 }) => !patientDiagnosticData.some(({ icdCode: id2 }) => id1 === id2)
    )
    const filteredToDelete = patientDiagnosticData.filter(
      ({ id: id1 }) => !diagnostics.some(({ id: id2 }) => id1 === id2)
    )
    if (filtered.length) {
      const dataToSubmit = filtered.map(dd => {
        dd.diagnosedDate = moment(dd.diagnosedDate).format("YYYY-MM-DD")
        return dd
      })
      dispatch(postPatientDiagnosticsData(patientId, dataToSubmit)).then((result) => {
        if (result?.status === 200 || result?.status === 201) {
          dispatch(getPatientDiagnosticsData({ patientId }))
        }
        if (callback) {
          callback()
        }
      })
    }
    if (filteredToDelete.length) {
      filteredToDelete.map((diagnotic) => {
        if (diagnotic?.id) {
          dispatch(deletePatientDiagnosticsData(patientId, diagnotic?.id)).then((result) => {
            if (result?.status === 200 || result?.status === 201) {
              dispatch(getPatientDiagnosticsData({ patientId }))
            }
          })
        }
      })
    }
  }

  const handleMonitoringSubmit = async (callback) => {
    const defaultPrograms = Object.keys(patientMonitoringData)
      .map(key =>
        patientMonitoringData[key].map(i =>
          i.programs.map(programs => {
            if (i?.providerId) {
              return {
                providerId: `${i.monitoringType} ${programs.programName}:${i.providerId}`,
                programId: programs.programId
              }
            }
            return {
              practitionerId: `${i.monitoringType} ${programs.programName}:${i.practitionerId}`,
              programId: programs.programId
            }
          })
        )
      )
      .flat(Infinity)

    const defaultProgramsProvidersOrPractitioners = defaultPrograms.map(
      program => program?.providerId ?? program?.practitionerId
    )
    const defaultProgramsProgramIds = defaultPrograms.map((program) => program?.programId)
    const arr = selectedRPM.filter(i =>
      !defaultProgramsProvidersOrPractitioners.includes(i.provider_practitioner_id) ? i : null
    )

    // providerToBeEdited is filtering the deviceId which are same (server fetched and client selected) just provider is differnet
    const providerToBeEdited = arr.filter((program) => (defaultProgramsProgramIds.includes(program.programId) ? program : null))
    for (const program of providerToBeEdited) {
      await dispatch(deleteMonitoringData(patientId, program.programId));
    }

    const selectedRPMIds = selectedRPM.map((program) => program?.programId)
    const removedProgram = defaultPrograms.filter(i => (!selectedRPMIds.includes(i.programId) ? i : null))
    for (const program of removedProgram) {
      await dispatch(deleteMonitoringData(patientId, program?.programId))
    }

    if (arr.length && arr.every(i => i.provider_practitioner_id !== null)) {
      await dispatch(
        postMonitoringData(
          patientId,
          arr.map(i => ({
            [`${i?.providerType}Id`]: i.provider_practitioner_id.split(":").pop(),
            monitoringId: i.programId
          }))
        )
      )
    } else {
      if (!arr.some(i => i.provider_practitioner_id)) {
        setCustomError(arr.map(i => (i.provider_practitioner_id === null ? i.programId : null)))
      }
    }
    dispatch(getPatientMonitoringData({ patientId }))
  }

  const handleOnSubmit = async () => {
    await handleDiagnosticValidation(async () => {
      if (!diagnosticError && !customError.length) {
        await handleDiagnosticsSubmit()
        await handleMonitoringSubmit()
        //dispatch(getPatientInfo({ patientId })).then(() => {
        //  dispatch(getPatientMonitoringData({ patientId })).then(() => {
        //    dispatch(getPatientDiagnosticsData({ patientId })).then(() => {
        // dispatch(getPatientInfo({ patientId }))
        // handleUpdateDiagnosticsClose()
        // setDiagnostics([])
        addToast(MONITORING_DIA_UPDT_MSG, {
          appearance: "success",
          autoDismiss: true
        })
        dispatch(getPatientMenuConfigAction(patientId))
        //   })
        handleClose()
        setDiagnostics([])
        setDiagnosticError(null)
        //  })
        // })
        // handleMonitoringSubmit(() => {
        //   handleDiagnosticsSubmit(() => {
        //     handleClose()
        //     setDiagnostics([])
        //     setDiagnosticError(null)
        //     dispatch(getPatientInfo({ patientId }))
        //     dispatch(getPatientMonitoringData({ patientId }))
        //   })
        // })
      } else if (customError.length) {
        scrollToElement(elementRefs, customError[0])
      }
    })
  }

  const filterOption = (input, option) =>
    (option?.label?.props?.children ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <Modal
      width={800}
      footer={null}
      open={updateMonitoringShow}
      onCancel={handleClose}
      title={<h4 className="text-xl">Update Monitoring</h4>}>
      <div className="pt-4">
        {loading || DiagnosticsLoading ? (
          <Loading />
        ) : (
          <>
            <div className="w-[95%] flex justify-between items-center">
              {rpmDevices?.map((d, index) => (
                <Checkbox
                  key={index}
                  ref={(el) => elementRefs.current[index] = el}
                  name={d.name}
                  className="uppercase"
                  disabled={
                    (d.name === "ccm" && selectedTypes?.find(i => i === "pcm")) ||
                    (d.name === "pcm" && selectedTypes?.find(i => i === "ccm"))
                  }
                  checked={selectedTypes.some(i => i === d.name)}
                  onChange={e => handleSelectedTypes(e)}
                >
                  {d.name}
                </Checkbox>
              ))}
            </div>

            {selectedTypes.map((type, index) => {
              return (
                <div key={index} className="mt-4">
                  <p className="uppercase font-semibold">{type}</p>
                  <div className="mt-3 flex items-center flex-wrap gap-4">
                    {rpmDevices
                      ?.find(d => d.name === type)
                      ?.programs?.map((p, ind) => (
                        <div key={ind} className="basis-full">
                          <Checkbox
                            key={p.id}
                            ref={(el) => elementRefs.current[p.id] = el}
                            name={p.name}
                            className="capitalize flex-wrap"
                            checked={selectedRPM.some(i => i.programId === p.id)}
                            onChange={e => handleSelectedRPMDevices(e, p.id, type)}>
                            {p.name}
                          </Checkbox>
                          {selectedRPM.some(i => i.programId === p.id) ? (
                            <>
                              <div className="mt-2">
                                <p className="mb-1">Select Provider Type <span className="text-danger">*</span></p>
                                <Radio.Group onChange={handleProviderTypeRadio} value={providerTypeRadio?.[`${type} ${p?.name}`]}>
                                  <Radio value={`practitioner~${type} ${p?.name}~${p?.id}`}>Practitioner</Radio>
                                  <Radio value={`provider~${type} ${p?.name}~${p?.id}`}>Provider</Radio>
                                </Radio.Group>
                              </div>
                              {!providerTypeRadio?.[`${type} ${p?.name}`] ? (
                                <small className="text-danger">Please Select A Type</small>
                              ) : null}
                              <div className="w-100 mt-3">
                                <label className="mb-1 capitalize" htmlFor={`practitioner-${p.id}`}>
                                  {p.name} Practitioner <span className="text-danger">*</span>
                                </label>
                                <Select
                                  showSearch
                                  className="w-100"
                                  id={`practitioner-${p.id}`}
                                  disabled={!/practitioner/.test(providerTypeRadio?.[`${type} ${p?.name}`])}
                                  filterOption={filterOption}
                                  options={getPractitionerOption(practitionerList, `${type} ${p.name}`)}
                                  value={getSelectedPractitioner(practitionerList, selectedRPM, `${type} ${p.name}`)}
                                  onChange={e => handlePractitionerChange(e, `${type} ${p.name}`)}
                                  status={
                                    /practitioner/.test(
                                      providerTypeRadio?.[`${type} ${p?.name}`]
                                    ) && customError.includes(p.id)
                                      ? "error"
                                      : undefined
                                  }
                                />
                                {/practitioner/.test(providerTypeRadio?.[`${type} ${p?.name}`]) &&
                                  customError.includes(p.id) ? (
                                  <small className="text-danger">
                                    Please Select A Practitioner
                                  </small>
                                ) : null}
                              </div>
                              <div className="w-100 mt-3">
                                <label className="mb-1 capitalize" htmlFor={`provider-${p.id}`}>
                                  {p.name} Provider <span className="text-danger">*</span>
                                </label>
                                <Select
                                  showSearch
                                  className="w-100"
                                  id={`provider-${p.id}`}
                                  disabled={!/provider/.test(providerTypeRadio?.[`${type} ${p?.name}`])}
                                  filterOption={filterOption}
                                  options={getProviderOption(providerList, `${type} ${p.name}`)}
                                  value={getSelectedProvider(providerList, selectedRPM, `${type} ${p.name}`)}
                                  onChange={e => handleProviderChange(e, `${type} ${p.name}`)}
                                  status={
                                    /provider/.test(
                                      providerTypeRadio?.[`${type} ${p?.name}`]
                                    ) && customError.includes(p.id)
                                      ? "error"
                                      : undefined
                                  }
                                />
                                {/provider/.test(providerTypeRadio?.[`${type} ${p?.name}`]) &&
                                  customError.includes(p.id) ? (
                                  <small className="text-danger">
                                    Please Select A Provider
                                  </small>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                        </div>
                      ))}
                  </div>
                </div>
              )
            })}

            <div className="add-patient-auto-suggest mt-3 mb-3">
              <p className="fs-6 mb-1">Diagnostic Codes Search</p>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  value,
                  onChange,
                  placeholder: "Type a Data"
                }}
              />
            </div>

            {patientDiagnosticData && diagnostics.length ? (
              <div className="mt-4">
                <div>
                  {diagnostics?.map((field, index) => {
                    return (
                      <div key={index} className="p-3 border-current rounded border-2 mb-3 pb-0">
                        <div className="d-flex w-100 align-items-center">
                          <Form.Group label="Name *" className="mb-3 w-100">
                            <Form.Control
                              required
                              disabled
                              name="name"
                              type="text"
                              value={field?.description}
                              placeholder="Name"
                              onChange={e => handleInputChange(e, index)}
                            />
                            <Form.Control.Feedback type="invalid" className="ms-1">
                              Diagnostics Name is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <div
                            onClick={() => handleDiagnosticDelete(field.id, field.icdCode)}
                            style={{ cursor: "pointer" }}
                            className="mb-3">
                            <FiMinusSquare className="fs-3 ms-2" />
                          </div>
                        </div>
                        <Form.Group label="Code *" className="mb-3 w-100">
                          <Form.Control
                            required
                            disabled
                            name="diagnosticId"
                            type="text"
                            value={field?.icdCode}
                            placeholder="Code"
                            onChange={e => handleInputChange(e, index)}
                          />
                          <Form.Control.Feedback type="invalid" className="ms-1">
                            Diagnostics Code is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Diagnostics Date *</Form.Label>
                          <DatePicker
                            selected={new Date(field?.diagnosedDate)}
                            onChange={date => {
                              handleDiagnosticsDate(date, index)
                            }}
                            dateFormatCalendar={"MMM yyyy"}
                            minDate={new Date().getFullYear() - 50}
                            maxDate={new Date()}
                            showPopperArrow={false}
                            placeholderText="Diagnostics Date"
                            className="border p-3 mb-2 rounded w-100"
                            withPortal
                          />
                          <Form.Control.Feedback
                            className="fs-6 text-uppercase fw-medium mb-3 text-danger"
                            type="invalid">
                            Diagnostics Date is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              "No Diagnostic Data Found"
            )}

            {diagnosticError ? (
              <div className="mt-4">
                <p className="text-danger font-semibold">{diagnosticError}</p>
              </div>
            ) : null}

            <div className="flex flex-wrap gap-3 mt-4">
              <Button size="large" type="primary" htmlType="submit" onClick={handleOnSubmit}>
                Submit
              </Button>
              <Button size="large" danger onClick={handleUpdateMonitoringClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default UpdateMonitoring
