import React from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

import { Row, Col, Input, Button, Divider, Spin, Carousel } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { googleLoginAction, validateLoginDetailsAction } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo-reg-logo-dark.png"
import loginImage from "../../assets/login-caretaker.png"
import { useState } from "react"
import LoginComponent from "./Login"
import OTPForm from "../ForgetPassword/OTPForm"
import ChangePasswordForm from "../ForgetPassword/ChangePasswordForm"
import { Mixpanel } from "../../utils/mixpanel"
import { GoogleLogin } from "@react-oauth/google"
import { NextArrow, PrevArrow } from "./CarouselArrow"
import FloatInput from "../../utils/FloatInput/FloatInput"
import { FaUserAlt } from "react-icons/fa"
import "./login.css"

const schema = yup
  .object({
    credential: yup
      .string()
      .required("Enter a Valid Email or Phone Number")
      .test("value-test", "Enter a Valid Email or Phone Number", function (value) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(value)
        if (!isValidEmail && !isValidPhone) {
          return false
        }
        return true
      })
  })
  .required()

const defaultValues = {
  credential: ""
}

const LoginHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [userName, setUserName] = useState("")
  const [showNext, setShowNext] = useState(false)

  const [otpValue, setOtpValue] = useState("")
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [changePasswordShow, setChangePasswordShow] = useState(false)

  const { loading: validateLoading, error: validateError } = useSelector(
    state => state.validateLoginDetails
  )

  const { loading: googleLoginLoading, error: googleLoginErr } = useSelector(
    state => state.googleLogin
  )

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const credentialWatcher = watch("credential")

  const onSubmit = async ({ credential }) => {
    setUserName(credential)
    let res = await dispatch(validateLoginDetailsAction(credential, location?.state?.prevUrl))
    // res?.details?.forgotOTP === false // removed below from if condition (bug-FIT-5534)
    if (res?.details?.onBoardingOTP === false && res?.details?.setPassword === false) {
      setShowNext(true)
    }
    // if (res?.details?.forgotOTP === true) {
    //   navigate('/forgot-password')
    // }
    if (res?.details?.onBoardingOTP === true) {
      setShowOTPForm(true)
    }
    if (res?.details?.setPassword === true) {
      // setChangePasswordShow(true)
      navigate("/forgot-password")
    }
  }

  const onSuccessGoogleLogin = ({ credential }) => dispatch(googleLoginAction({ credential }))

  return (
    <Row align="middle" className="w-full h-screen flex flex-row-reverse login-container">
      <Col
        xs={24}
        lg={12}
        className="relative px-4 h-full flex flex-col justify-center items-center">
        <img alt="logo" src={fitpeoLogo} className="absolute top-6 md:top-3 md:right-7 w-[9vw]" />
        <div className="max-w-2xl w-full md:px-24">
          <h1 className="font-semibold text-xl leading-none">Log in to FitPeo</h1>
          <h2 className="mt-3 mb-4 text-secondary leading-none">
            Enter Email Address or Phone Number
          </h2>
          {showNext ? (
            <LoginComponent userName={userName} />
          ) : showOTPForm ? (
            <OTPForm
              credential={userName}
              setOtpValue={setOtpValue}
              setShowNext={setShowOTPForm}
              setChangePasswordShow={setChangePasswordShow}
            />
          ) : changePasswordShow ? (
            <ChangePasswordForm
              otp={otpValue}
              userName={userName}
              setChangePasswordShow={setChangePasswordShow}
            />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-1 min-h-[65px]">
                  <Controller
                    name="credential"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FloatInput label="Email or Phone" placeholder="Email or Phone Number">
                        <Input
                          size="large"
                          prefix={<FaUserAlt className="text-[#666B78]" size={15} />}
                          status={errors.credential ? "error" : undefined}
                          {...field}
                          onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                        />
                      </FloatInput>
                    )}
                  />
                  {errors.credential ? (
                    <small className="text-danger">{errors.credential.message}</small>
                  ) : null}

                  {validateError !== undefined && typeof validateError === "string" && (
                    <NavLink
                      className="mt-2 mb-3 text-primary float-end px-0 no-underline"
                      to="/forgot-password"
                      state={{ enteredCred: userName }}>
                      Forgot email or phone?
                    </NavLink>
                  )}
                </div>

                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={Boolean(validateLoading) || !credentialWatcher?.length}
                  onClick={() => {
                    Mixpanel.track("Login Validate")
                  }}>
                  {validateLoading ? "Loading..." : "Next"}
                </Button>

                {validateError !== undefined && typeof validateError === "string" && (
                  <p className="mt-3 text-danger text-center font-medium">{validateError}</p>
                )}
              </form>
            </>
          )}

          <Divider plain>
            <span className="text-secondary text-xs">Or</span>
          </Divider>

          <Spin spinning={googleLoginLoading === true}>
            <div className="mt-4 mb-3 grid place-content-center">
              <GoogleLogin
                onSuccess={onSuccessGoogleLogin}
                onError={() => {
                  console.log("Login Failed")
                }}
              />
            </div>
          </Spin>

          {googleLoginErr !== undefined && typeof googleLoginErr === "string" && (
            <p className="text-danger text-center font-medium">{googleLoginErr}</p>
          )}
        </div>
      </Col>
      <Col xs={0} lg={12} className="h-full">
        <div className="relative h-full flex items-center flex-col-reverse">
          <div
            className="absolute inset-0 bg-cover bg-center rounded-xl overflow-hidden my-3 ml-4"
            style={{ backgroundImage: `url(${loginImage})` }}>
            <div className="absolute inset-0 bg-black/50 backdrop-blur-lg rounded-xl" />
          </div>

          {/* Square Image */}
          <div className="absolute flex items-center justify-center h-full px-8 py-3 pl-12">
            <img src={loginImage} alt="patient-login" className="object-cover h-full" />
          </div>
          <div className="w-full">
            <Carousel
              autoplay
              arrows
              // dots={false}
              className="h-[400px] ml-4 mb-3 pt-32"
              style={{
                borderRadius: "12px",
                color: "#FFF",
                background: "linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center"
              }}
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}>
              <div>
                <h2 className="text-2xl font-semibold">
                  Gaining Insight into Patient Health from afar
                </h2>
                <h3 className="text-base mt-2 px-2">
                  This technology empowers both patients and clinicians, leading to more
                  personalized care and better health outcomes.
                </h3>
              </div>
              <div>
                <h2 className="text-2xl font-semibold">
                  Generate tailored reports for detailed health analysis.
                </h2>
                <h3 className="text-base mt-2 px-2">
                  Access a comprehensive snapshot of patient health, including key vitals and recent
                  activity updates.
                </h3>
              </div>
              <div>
                <h2 className="text-2xl font-semibold">
                  Track adherence to prescribed medication schedules.
                </h2>
                <h3 className="text-base mt-2  px-2">
                  Ensure patients are following their prescribed medication schedules with real-time
                  tracking and reminders.
                </h3>
              </div>
            </Carousel>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default LoginHome
